@import url("font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Source+Sans+Pro:400,600,400italic,600italic");

/*
	Scalar by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/


/* Additional */

.green {
    color: green !important;
}

/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
body {
    -webkit-text-size-adjust: none;
}

/* Box Model */

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Containers */

.container {
    margin-left: auto;
    margin-right: auto;
}
.container.\31 25\25 {
    width: 100%;
    max-width: 1750px;
    min-width: 1400px;
}
.container.\37 5\25 {
    width: 1050px;
}
.container.\35 0\25 {
    width: 700px;
}
.container.\32 5\25 {
    width: 350px;
}
.container {
    width: 1400px;
}

/*@media screen and (max-width: 1680px) {*/

@media screen and (min-width: 1281px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 1500px;
        min-width: 1200px;
    }
    .container.\37 5\25 {
        width: 900px;
    }
    .container.\35 0\25 {
        width: 600px;
    }
    .container.\32 5\25 {
        width: 300px;
    }
    .container {
        width: 1200px;
    }
}
@media screen and (max-width: 1280px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 1200px;
        min-width: 960px;
    }
    .container.\37 5\25 {
        width: 720px;
    }
    .container.\35 0\25 {
        width: 480px;
    }
    .container.\32 5\25 {
        width: 240px;
    }
    .container {
        width: 960px;
    }
}
@media screen and (max-width: 980px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }
    .container.\37 5\25 {
        width: 67.5%;
    }
    .container.\35 0\25 {
        width: 45%;
    }
    .container.\32 5\25 {
        width: 22.5%;
    }
    .container {
        width: 100%;
    }
}
@media screen and (max-width: 736px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }
    .container.\37 5\25 {
        width: 67.5%;
    }
    .container.\35 0\25 {
        width: 45%;
    }
    .container.\32 5\25 {
        width: 22.5%;
    }
    .container {
        width: 100% !important;
    }
}
@media screen and (max-width: 480px) {
    .container.\31 25\25 {
        width: 100%;
        max-width: 112.5%;
        min-width: 90%;
    }
    .container.\37 5\25 {
        width: 67.5%;
    }
    .container.\35 0\25 {
        width: 45%;
    }
    .container.\32 5\25 {
        width: 22.5%;
    }
    .container {
        width: 100% !important;
    }
}

/* Grid */

.row {
    border-bottom: solid 1px transparent;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.row > * {
    float: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.row:after,
.row:before {
    content: '';
    display: block;
    clear: both;
    height: 0;
}
.row.uniform > * >:first-child {
    margin-top: 0;
}
.row.uniform > * >:last-child {
    margin-bottom: 0;
}
.row.\30 \25 > * {
    padding: 0 0 0 0em;
}
.row.\30 \25 {
    margin: 0 0 -1px 0em;
}
.row.uniform.\30 \25 > * {
    padding: 0em 0 0 0em;
}
.row.uniform.\30 \25 {
    margin: 0em 0 -1px 0em;
}
.row > * {
    padding: 0 0 0 2em;
}
.row {
    margin: 0 0 -1px -2em;
}
.row.uniform > * {
    padding: 2em 0 0 2em;
}
.row.uniform {
    margin: 0em 0 -1px -2em;
}
.row.\32 00\25 > * {
    padding: 0 0 0 4em;
}
.row.\32 00\25 {
    margin: 0 0 -1px -4em;
}
.row.uniform.\32 00\25 > * {
    padding: 4em 0 0 4em;
}
.row.uniform.\32 00\25 {
    margin: -4em 0 -1px -4em;
}
.row.\31 50\25 > * {
    padding: 0 0 0 3em;
}
.row.\31 50\25 {
    margin: 0 0 -1px -3em;
}
.row.uniform.\31 50\25 > * {
    padding: 3em 0 0 3em;
}
.row.uniform.\31 50\25 {
    margin: -3em 0 -1px -3em;
}
.row.\35 0\25 > * {
    padding: 0 0 0 1em;
}
.row.\35 0\25 {
    margin: 0 0 -1px -1em;
}
.row.uniform.\35 0\25 > * {
    padding: 1em 0 0 1em;
}
.row.uniform.\35 0\25 {
    margin: -1em 0 -1px -1em;
}
.row.\32 5\25 > * {
    padding: 0 0 0 0.5em;
}
.row.\32 5\25 {
    margin: 0 0 -1px -0.5em;
}
.row.uniform.\32 5\25 > * {
    padding: 0.5em 0 0 0.5em;
}
.row.uniform.\32 5\25 {
    margin: -0.5em 0 -1px -0.5em;
}
.\31 2u,
.\31 2u\24 {
    width: 100%;
    clear: none;
    margin-left: 0;
}
.\31 1u,
.\31 1u\24 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
}
.\31 0u,
.\31 0u\24 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
}
.\39 u,
.\39 u\24 {
    width: 75%;
    clear: none;
    margin-left: 0;
}
.\38 u,
.\38 u\24 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
}
.\37 u,
.\37 u\24 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
}
.\36 u,
.\36 u\24 {
    width: 50%;
    clear: none;
    margin-left: 0;
}
.\35 u,
.\35 u\24 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
}
.\34 u,
.\34 u\24 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
}
.\33 u,
.\33 u\24 {
    width: 25%;
    clear: none;
    margin-left: 0;
}
.\32 u,
.\32 u\24 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
}
.\31 u,
.\31 u\24 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
}
.\31 2u\24 + *,
.\31 1u\24 + *,
.\31 0u\24 + *,
.\39 u\24 + *,
.\38 u\24 + *,
.\37 u\24 + *,
.\36 u\24 + *,
.\35 u\24 + *,
.\34 u\24 + *,
.\33 u\24 + *,
.\32 u\24 + *,
.\31 u\24 + * {
    clear: left;
}
.\-11u {
    margin-left: 91.66667%;
}
.\-10u {
    margin-left: 83.33333%;
}
.\-9u {
    margin-left: 75%;
}
.\-8u {
    margin-left: 66.66667%;
}
.\-7u {
    margin-left: 58.33333%;
}
.\-6u {
    margin-left: 50%;
}
.\-5u {
    margin-left: 41.66667%;
}
.\-4u {
    margin-left: 33.33333%;
}
.\-3u {
    margin-left: 25%;
}
.\-2u {
    margin-left: 16.66667%;
}
.\-1u {
    margin-left: 8.33333%;
}

/*@media screen and (max-width: 1680px) {*/

@media screen and (min-width: 1281px) {
    .row > * {
        padding: 0 0 0 2em;
    }
    .row {
        margin: 0 0 -1px -2em;
    }
    .row.uniform > * {
        /*        padding: 2em 0 0 2em;*/
        padding: 2em 0 0 0;
    }
    .row.uniform {
        /*        margin: -2em 0 -1px -2em;*/
        margin: 2.5em 0 -1px -2em !important;
        /* !! Geändert 07.02.2017 */
    }
    .row.uniform.ml1 {
        margin: 2.5em 0 -1px -1em !important;
    }
    .row.\32 00\25 > * {
        padding: 0 0 0 4em;
    }
    .row.\32 00\25 {
        margin: 0 0 -1px -4em;
    }
    .row.uniform.\32 00\25 > * {
        padding: 4em 0 0 2em;
    }
    .row.uniform.\32 00\25 {
        margin: -4em 0 -1px -4em;
    }
    .row.\31 50\25 > * {
        padding: 0 0 0 3em;
    }
    .row.\31 50\25 {
        margin: 0 0 -1px -3em;
    }
    .row.uniform.\31 50\25 > * {
        padding: 3em 0 0 3em;
    }
    .row.uniform.\31 50\25 {
        margin: -3em 0 -1px -3em;
    }
    .row.\35 0\25 > * {
        padding: 0 0 0 1em;
    }
    .row.\35 0\25 {
        margin: 0 0 -1px -1em;
    }
    .row.uniform.\35 0\25 > * {
        padding: 1em 0 0 1em;
    }
    .row.uniform.\35 0\25 {
        margin: -1em 0 -1px -1em;
    }
    .row.\32 5\25 > * {
        padding: 0 0 0 0.5em;
    }
    .row.\32 5\25 {
        margin: 0 0 -1px -0.5em;
    }
    .row.uniform.\32 5\25 > * {
        padding: 0.5em 0 0 0.5em;
    }
    .row.uniform.\32 5\25 {
        margin: -0.5em 0 -1px -0.5em;
    }
    .\31 2u\28xlarge\29,
    .\31 2u\24\28xlarge\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }
    .\31 1u\28xlarge\29,
    .\31 1u\24\28xlarge\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 0u\28xlarge\29,
    .\31 0u\24\28xlarge\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\39 u\28xlarge\29,
    .\39 u\24\28xlarge\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }
    .\38 u\28xlarge\29,
    .\38 u\24\28xlarge\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\37 u\28xlarge\29,
    .\37 u\24\28xlarge\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\36 u\28xlarge\29,
    .\36 u\24\28xlarge\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }
    .\35 u\28xlarge\29,
    .\35 u\24\28xlarge\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\34 u\28xlarge\29,
    .\34 u\24\28xlarge\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\33 u\28xlarge\29,
    .\33 u\24\28xlarge\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }
    .\32 u\28xlarge\29,
    .\32 u\24\28xlarge\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 u\28xlarge\29,
    .\31 u\24\28xlarge\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\31 2u\24\28xlarge\29 + *,
    .\31 1u\24\28xlarge\29 + *,
    .\31 0u\24\28xlarge\29 + *,
    .\39 u\24\28xlarge\29 + *,
    .\38 u\24\28xlarge\29 + *,
    .\37 u\24\28xlarge\29 + *,
    .\36 u\24\28xlarge\29 + *,
    .\35 u\24\28xlarge\29 + *,
    .\34 u\24\28xlarge\29 + *,
    .\33 u\24\28xlarge\29 + *,
    .\32 u\24\28xlarge\29 + *,
    .\31 u\24\28xlarge\29 + * {
        clear: left;
    }
    .\-11u\28xlarge\29 {
        margin-left: 91.66667%;
    }
    .\-10u\28xlarge\29 {
        margin-left: 83.33333%;
    }
    .\-9u\28xlarge\29 {
        margin-left: 75%;
    }
    .\-8u\28xlarge\29 {
        margin-left: 66.66667%;
    }
    .\-7u\28xlarge\29 {
        margin-left: 58.33333%;
    }
    .\-6u\28xlarge\29 {
        margin-left: 50%;
    }
    .\-5u\28xlarge\29 {
        margin-left: 41.66667%;
    }
    .\-4u\28xlarge\29 {
        margin-left: 33.33333%;
    }
    .\-3u\28xlarge\29 {
        margin-left: 25%;
    }
    .\-2u\28xlarge\29 {
        margin-left: 16.66667%;
    }
    .\-1u\28xlarge\29 {
        margin-left: 8.33333%;
    }
}
@media screen and (max-width: 1280px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }
    .row {
        margin: 0 0 -1px -1.5em;
    }
    .row.uniform > * {
        padding: 1em 0 0 0;
    }
    .row.uniform {
        /*        margin: -1.5em 0 -1px -1.5em;*/
    }
    .row.\32 00\25 > * {
        padding: 0 0 0 3em;
    }
    .row.\32 00\25 {
        margin: 0 0 -1px -3em;
    }
    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }
    .row.uniform.\32 00\25 {
        margin: -3em 0 -1px -3em;
    }
    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }
    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }
    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }
    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }
    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }
    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }
    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }
    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }
    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }
    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }
    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }
    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }
    .\31 2u\28large\29,
    .\31 2u\24\28large\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }
    .\31 1u\28large\29,
    .\31 1u\24\28large\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 0u\28large\29,
    .\31 0u\24\28large\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\39 u\28large\29,
    .\39 u\24\28large\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }
    .\38 u\28large\29,
    .\38 u\24\28large\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\37 u\28large\29,
    .\37 u\24\28large\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\36 u\28large\29,
    .\36 u\24\28large\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }
    .\35 u\28large\29,
    .\35 u\24\28large\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\34 u\28large\29,
    .\34 u\24\28large\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\33 u\28large\29,
    .\33 u\24\28large\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }
    .\32 u\28large\29,
    .\32 u\24\28large\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 u\28large\29,
    .\31 u\24\28large\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\31 2u\24\28large\29 + *,
    .\31 1u\24\28large\29 + *,
    .\31 0u\24\28large\29 + *,
    .\39 u\24\28large\29 + *,
    .\38 u\24\28large\29 + *,
    .\37 u\24\28large\29 + *,
    .\36 u\24\28large\29 + *,
    .\35 u\24\28large\29 + *,
    .\34 u\24\28large\29 + *,
    .\33 u\24\28large\29 + *,
    .\32 u\24\28large\29 + *,
    .\31 u\24\28large\29 + * {
        clear: left;
    }
    .\-11u\28large\29 {
        margin-left: 91.66667%;
    }
    .\-10u\28large\29 {
        margin-left: 83.33333%;
    }
    .\-9u\28large\29 {
        margin-left: 75%;
    }
    .\-8u\28large\29 {
        margin-left: 66.66667%;
    }
    .\-7u\28large\29 {
        margin-left: 58.33333%;
    }
    .\-6u\28large\29 {
        margin-left: 50%;
    }
    .\-5u\28large\29 {
        margin-left: 41.66667%;
    }
    .\-4u\28large\29 {
        margin-left: 33.33333%;
    }
    .\-3u\28large\29 {
        margin-left: 25%;
    }
    .\-2u\28large\29 {
        margin-left: 16.66667%;
    }
    .\-1u\28large\29 {
        margin-left: 8.33333%;
    }
}
@media screen and (max-width: 980px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }
    .row {
        /*        margin: 0 !important; ???*/
    }
    .row.uniform > * {
        padding: 0;
        float: none;
    }
    .row.uniform {
        /*        margin: -1.5em 0 -1px -1.5em;*/
    }
    .row.\32 00\25 > * {
        padding: 0 0 0 3em;
    }
    .row.\32 00\25 {
        margin: 0 0 -1px -3em;
    }
    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }
    .row.uniform.\32 00\25 {
        margin: -3em 0 -1px -3em;
    }
    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }
    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }
    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }
    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }
    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }
    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }
    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }
    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }
    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }
    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }
    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }
    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }
    .\31 2u\28medium\29,
    .\31 2u\24\28medium\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }
    .\31 1u\28medium\29,
    .\31 1u\24\28medium\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 0u\28medium\29,
    .\31 0u\24\28medium\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\39 u\28medium\29,
    .\39 u\24\28medium\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }
    .\38 u\28medium\29,
    .\38 u\24\28medium\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\37 u\28medium\29,
    .\37 u\24\28medium\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\36 u\28medium\29,
    .\36 u\24\28medium\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }
    .\35 u\28medium\29,
    .\35 u\24\28medium\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\34 u\28medium\29,
    .\34 u\24\28medium\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\33 u\28medium\29,
    .\33 u\24\28medium\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }
    .\32 u\28medium\29,
    .\32 u\24\28medium\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 u\28medium\29,
    .\31 u\24\28medium\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\31 2u\24\28medium\29 + *,
    .\31 1u\24\28medium\29 + *,
    .\31 0u\24\28medium\29 + *,
    .\39 u\24\28medium\29 + *,
    .\38 u\24\28medium\29 + *,
    .\37 u\24\28medium\29 + *,
    .\36 u\24\28medium\29 + *,
    .\35 u\24\28medium\29 + *,
    .\34 u\24\28medium\29 + *,
    .\33 u\24\28medium\29 + *,
    .\32 u\24\28medium\29 + *,
    .\31 u\24\28medium\29 + * {
        clear: left;
    }
    .\-11u\28medium\29 {
        margin-left: 91.66667%;
    }
    .\-10u\28medium\29 {
        margin-left: 83.33333%;
    }
    .\-9u\28medium\29 {
        margin-left: 75%;
    }
    .\-8u\28medium\29 {
        margin-left: 66.66667%;
    }
    .\-7u\28medium\29 {
        margin-left: 58.33333%;
    }
    .\-6u\28medium\29 {
        margin-left: 50%;
    }
    .\-5u\28medium\29 {
        margin-left: 41.66667%;
    }
    .\-4u\28medium\29 {
        margin-left: 33.33333%;
    }
    .\-3u\28medium\29 {
        margin-left: 25%;
    }
    .\-2u\28medium\29 {
        margin-left: 16.66667%;
    }
    .\-1u\28medium\29 {
        margin-left: 8.33333%;
    }
}
@media screen and (max-width: 736px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }
    .row {
        margin: 0 0 -1px -1.5em;
    }
    .row.uniform > * {
        padding: 1.5em 0 0 1.5em;
    }
    .row.uniform {
        margin: -1.5em 0 -1px -1.5em;
    }
    .row.\32 00\25 > * {
        padding: 0;
    }
    .row.\32 00\25 {
        /*        margin: 0 0 -1px -3em;*/
        margin: 0;
    }
    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }
    .row.uniform.\32 00\25 {
        margin: -3em 0 -1px -3em;
    }
    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }
    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }
    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }
    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }
    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }
    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }
    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }
    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }
    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }
    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }
    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }
    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }
    .\31 2u\28small\29,
    .\31 2u\24\28small\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }
    .\31 1u\28small\29,
    .\31 1u\24\28small\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 0u\28small\29,
    .\31 0u\24\28small\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\39 u\28small\29,
    .\39 u\24\28small\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }
    .\38 u\28small\29,
    .\38 u\24\28small\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\37 u\28small\29,
    .\37 u\24\28small\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\36 u\28small\29,
    .\36 u\24\28small\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }
    .\35 u\28small\29,
    .\35 u\24\28small\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\34 u\28small\29,
    .\34 u\24\28small\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\33 u\28small\29,
    .\33 u\24\28small\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }
    .\32 u\28small\29,
    .\32 u\24\28small\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 u\28small\29,
    .\31 u\24\28small\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\31 2u\24\28small\29 + *,
    .\31 1u\24\28small\29 + *,
    .\31 0u\24\28small\29 + *,
    .\39 u\24\28small\29 + *,
    .\38 u\24\28small\29 + *,
    .\37 u\24\28small\29 + *,
    .\36 u\24\28small\29 + *,
    .\35 u\24\28small\29 + *,
    .\34 u\24\28small\29 + *,
    .\33 u\24\28small\29 + *,
    .\32 u\24\28small\29 + *,
    .\31 u\24\28small\29 + * {
        clear: left;
    }
    .\-11u\28small\29 {
        margin-left: 91.66667%;
    }
    .\-10u\28small\29 {
        margin-left: 83.33333%;
    }
    .\-9u\28small\29 {
        margin-left: 75%;
    }
    .\-8u\28small\29 {
        margin-left: 66.66667%;
    }
    .\-7u\28small\29 {
        margin-left: 58.33333%;
    }
    .\-6u\28small\29 {
        margin-left: 50%;
    }
    .\-5u\28small\29 {
        margin-left: 41.66667%;
    }
    .\-4u\28small\29 {
        margin-left: 33.33333%;
    }
    .\-3u\28small\29 {
        margin-left: 25%;
    }
    .\-2u\28small\29 {
        margin-left: 16.66667%;
    }
    .\-1u\28small\29 {
        margin-left: 8.33333%;
    }
}
@media screen and (max-width: 480px) {
    .row > * {
        padding: 0 0 0 1.5em;
    }
    .row {
        margin: 0 0 -1px -1.5em;
    }
    .row.uniform > * {
        padding: 1.5em 0 0 1.5em;
    }
    .row.uniform {
        margin: -1.5em 0 -1px -1.5em;
    }
    .row.\32 00\25 > * {
        padding: 0;
    }
    .row.\32 00\25 {
        /*        margin: 0 0 -1px -3em;*/
        margin: 0;
    }
    .row.uniform.\32 00\25 > * {
        padding: 3em 0 0 3em;
    }
    .row.uniform.\32 00\25 {
        margin: 0em 0 -1px -3em;
    }
    .row.\31 50\25 > * {
        padding: 0 0 0 2.25em;
    }
    .row.\31 50\25 {
        margin: 0 0 -1px -2.25em;
    }
    .row.uniform.\31 50\25 > * {
        padding: 2.25em 0 0 2.25em;
    }
    .row.uniform.\31 50\25 {
        margin: -2.25em 0 -1px -2.25em;
    }
    .row.\35 0\25 > * {
        padding: 0 0 0 0.75em;
    }
    .row.\35 0\25 {
        margin: 0 0 -1px -0.75em;
    }
    .row.uniform.\35 0\25 > * {
        padding: 0.75em 0 0 0.75em;
    }
    .row.uniform.\35 0\25 {
        margin: -0.75em 0 -1px -0.75em;
    }
    .row.\32 5\25 > * {
        padding: 0 0 0 0.375em;
    }
    .row.\32 5\25 {
        margin: 0 0 -1px -0.375em;
    }
    .row.uniform.\32 5\25 > * {
        padding: 0.375em 0 0 0.375em;
    }
    .row.uniform.\32 5\25 {
        margin: -0.375em 0 -1px -0.375em;
    }
    .\31 2u\28xsmall\29,
    .\31 2u\24\28xsmall\29 {
        width: 100%;
        clear: none;
        margin-left: 0;
    }
    .\31 1u\28xsmall\29,
    .\31 1u\24\28xsmall\29 {
        width: 91.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 0u\28xsmall\29,
    .\31 0u\24\28xsmall\29 {
        width: 83.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\39 u\28xsmall\29,
    .\39 u\24\28xsmall\29 {
        width: 75%;
        clear: none;
        margin-left: 0;
    }
    .\38 u\28xsmall\29,
    .\38 u\24\28xsmall\29 {
        width: 66.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\37 u\28xsmall\29,
    .\37 u\24\28xsmall\29 {
        width: 58.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\36 u\28xsmall\29,
    .\36 u\24\28xsmall\29 {
        width: 50%;
        clear: none;
        margin-left: 0;
    }
    .\35 u\28xsmall\29,
    .\35 u\24\28xsmall\29 {
        width: 41.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\34 u\28xsmall\29,
    .\34 u\24\28xsmall\29 {
        width: 33.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\33 u\28xsmall\29,
    .\33 u\24\28xsmall\29 {
        width: 25%;
        clear: none;
        margin-left: 0;
    }
    .\32 u\28xsmall\29,
    .\32 u\24\28xsmall\29 {
        width: 16.6666666667%;
        clear: none;
        margin-left: 0;
    }
    .\31 u\28xsmall\29,
    .\31 u\24\28xsmall\29 {
        width: 8.3333333333%;
        clear: none;
        margin-left: 0;
    }
    .\31 2u\24\28xsmall\29 + *,
    .\31 1u\24\28xsmall\29 + *,
    .\31 0u\24\28xsmall\29 + *,
    .\39 u\24\28xsmall\29 + *,
    .\38 u\24\28xsmall\29 + *,
    .\37 u\24\28xsmall\29 + *,
    .\36 u\24\28xsmall\29 + *,
    .\35 u\24\28xsmall\29 + *,
    .\34 u\24\28xsmall\29 + *,
    .\33 u\24\28xsmall\29 + *,
    .\32 u\24\28xsmall\29 + *,
    .\31 u\24\28xsmall\29 + * {
        clear: left;
    }
    .\-11u\28xsmall\29 {
        margin-left: 91.66667%;
    }
    .\-10u\28xsmall\29 {
        margin-left: 83.33333%;
    }
    .\-9u\28xsmall\29 {
        margin-left: 75%;
    }
    .\-8u\28xsmall\29 {
        margin-left: 66.66667%;
    }
    .\-7u\28xsmall\29 {
        margin-left: 58.33333%;
    }
    .\-6u\28xsmall\29 {
        margin-left: 50%;
    }
    .\-5u\28xsmall\29 {
        margin-left: 41.66667%;
    }
    .\-4u\28xsmall\29 {
        margin-left: 33.33333%;
    }
    .\-3u\28xsmall\29 {
        margin-left: 25%;
    }
    .\-2u\28xsmall\29 {
        margin-left: 16.66667%;
    }
    .\-1u\28xsmall\29 {
        margin-left: 8.33333%;
    }
}

/* Basic */

body {
    background: #f5f5f5;
}
body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
    -moz-animation: none !important;
    -webkit-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    -moz-transition: none !important;
    -webkit-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}
body,
input,
select,
textarea {
    color: #868686;
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 13pt;
    font-weight: 400;
    line-height: 1.65em;
}
a {
    color: #95D253;
    text-decoration: underline;
}
a:hover {
    text-decoration: none;
}
strong,
b {
    color: #7f7f7f;
    font-weight: 600;
}
em,
i {
    font-style: italic;
}
p {
    margin: 0 0 2em 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #7f7f7f;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1em;
    margin: 0 0 1em 0;
    text-transform: uppercase;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    text-decoration: none;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: inherit !important;
}
h2 {
    font-size: 1.75em;
    line-height: 1.5em;
}
h3 {
    font-size: 1.25em;
    line-height: 1.5em;
}
h4 {
    font-size: 1.1em;
    line-height: 1.5em;
}
h5 {
    font-size: 0.9em;
    line-height: 1.5em;
}
h6 {
    font-size: 0.7em;
    line-height: 1.5em;
}
sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}
sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}
hr {
    border: 0;
    border-bottom: solid 2px rgba(144, 144, 144, 0.25);
    margin: 3em 0;
}
hr.major {
    margin: 6em 0;
}
blockquote {
    border-left: solid 4px rgba(144, 144, 144, 0.25);
    font-style: italic;
    margin: 0 0 2em 0;
    padding: 0.5em 0 0.5em 2em;
}
blockquote.testimonial {
    border: 0;
    margin-top: 1em;
    padding: 0;
    text-align: center;
}
blockquote.testimonial p {
    background: rgba(144, 144, 144, 0.2);
    margin: 0 auto 2.5em auto;
    padding: 1em 2em;
    position: relative;
    max-width: 22em;
}
blockquote.testimonial p:after {
    border-left: solid 1em transparent;
    border-right: solid 1em transparent;
    border-top: solid 1em rgba(144, 144, 144, 0.2);
    bottom: -2em;
    content: '';
    height: 2em;
    left: 50%;
    margin: 0 0 0 -1em;
    position: absolute;
    width: 2em;
}
blockquote.testimonial cite {
    display: block;
}
blockquote.testimonial cite .image {
    display: inline-block;
    margin: 0 0 1em 0;
}
blockquote.testimonial cite .name {
    color: #7f7f7f;
    display: block;
    font-style: normal;
}
blockquote.testimonial cite .title {
    color: #A5A5a5;
    display: block;
    font-style: normal;
}
code {
    background: rgba(144, 144, 144, 0.075);
    border: solid 1px rgba(144, 144, 144, 0.25);
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0.25em;
    padding: 0.25em 0.65em;
}
pre {
    -webkit-overflow-scrolling: touch;
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0 2em 0;
}
pre code {
    display: block;
    line-height: 1.75em;
    padding: 1em 1.5em;
    overflow-x: auto;
}
.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}

/* Section/Article */

section.special,
article.special {
    text-align: center;
}
header p {
    color: #A5A5a5;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0.05em;
    margin: 0 0 2em 0;
    position: relative;
    text-transform: uppercase;
}
header h2 + p {
    font-size: 1em;
    margin-top: -0.5em;
    line-height: 1.5em;
}
header h3 + p {
    font-size: 1.1em;
    margin-top: -0.8em;
    line-height: 1.5em;
}
header h4 + p,
header h5 + p,
header h6 + p {
    font-size: 0.9em;
    margin-top: -0.6em;
    line-height: 1.5em;
}
header.major {
    margin: 0 0 4em 0;
    text-align: center;
}
header.major:after {
    height: 2px;
    width: 20em;
    max-width: 60%;
    background: rgba(144, 144, 144, 0.25);
    content: '';
    display: block;
    margin: 0 auto;
}

/* Form */

form {
    margin: 0 0 2em 0;
}
label {
    color: #7f7f7f;
    display: block;
    font-size: 0.9em;
    font-weight: 600;
    margin: 0 0 1em 0;
}
input[type="text"],
input[type="password"],
input[type="email"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: rgba(144, 144, 144, 0.075);
    border: none;
    border: solid 1px rgba(144, 144, 144, 0.25);
    border-radius: 0 !important;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
}
input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
    box-shadow: none;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
    border-color: #95D253;
    box-shadow: 0 0 0 1px #95D253;
}
.select-wrapper {
    text-decoration: none;
    display: block;
    position: relative;
}
.select-wrapper:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}
.select-wrapper:before {
    content: '\f078';
    color: rgba(144, 144, 144, 0.25);
    display: block;
    height: 2.75em;
    line-height: 2.75em;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 2.75em;
}
.select-wrapper select::-ms-expand {
    display: none;
}
input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 2.75em;
}
textarea {
    padding: 0.75em 1em;
}
input[type="checkbox"],
input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;
}
input[type="checkbox"] + label,
input[type="radio"] + label {
    text-decoration: none;
    color: #868686;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 400;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative;
}
input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}
input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
    background: rgba(144, 144, 144, 0.075);
    border: solid 1px rgba(144, 144, 144, 0.25);
    content: '';
    display: inline-block;
    height: 1.65em;
    left: 0;
    line-height: 1.58125em;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.65em;
}
input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
    background: #8b1b8b;
    border-color: #8b1b8b;
    color: #ffffff;
    content: '\f00c';
}
input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
    border-color: #95D253;
    box-shadow: 0 0 0 1px #95D253;
}
input[type="radio"] + label:before {
    border-radius: 100%;
}
::-webkit-input-placeholder {
    color: #A5A5a5 !important;
    opacity: 1.0;
}
:-moz-placeholder {
    color: #A5A5a5 !important;
    opacity: 1.0;
}
::-moz-placeholder {
    color: #A5A5a5 !important;
    opacity: 1.0;
}
:-ms-input-placeholder {
    color: #A5A5a5 !important;
    opacity: 1.0;
}
.formerize-placeholder {
    color: #A5A5a5 !important;
    opacity: 1.0;
}

/* Box */

.box {
    border: solid 1px rgba(144, 144, 144, 0.25);
    /*    margin-bottom: 2em;*/
    padding: 1.5em;
}
.box >:last-child,
.box >:last-child >:last-child,
.box >:last-child >:last-child >:last-child {
    margin-bottom: 0;
}
.box.alt {
    border: 0;
    border-radius: 0;
    padding: 0;
}

/* Icon */

.icon {
    text-decoration: none;
    border-bottom: none;
    position: relative;
}
.icon:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-transform: none !important;
}
.icon > .label {
    display: none;
}
.icon.major {
    display: block;
    height: 4.5em;
    line-height: 4.5em;
    margin: 1em auto 3em auto;
    position: relative;
    text-align: center;
    width: 4.5em;
}
.icon.major:before {
    color: #ffffff;
    font-size: 2em;
    position: relative;
    z-index: 1;
}
.icon.major:after {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #95D253;
    content: '';
    height: inherit;
    left: 0;
    line-height: inherit;
    position: absolute;
    top: 0;
    width: inherit;
}
.icon.major.fa-paper-plane:before {
    left: -0.125em;
}

/* Image */

.image {
    border: 0;
    display: inline-block;
    position: relative;
}
.image img {
    display: block;
}
.image.left {
    float: left;
    padding: 0 1.5em 1em 0;
    top: 0.25em;
}
.image.right {
    float: right;
    padding: 0 0 1em 1.5em;
    top: 0.25em;
}
.image.left,
.image.right {
    max-width: 40%;
}
.image.left img,
.image.right img {
    width: 100%;
}
.image.fit {
    display: block;
    margin: 0 0 2em 0;
    width: 100%;
}
.image.fit img {
    width: 100%;
}
.image.style1 {
    background: #ffffff;
    border-radius: 0.5em;
    border: solid 2em #ffffff;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.125);
    margin-top: -0.25em;
}
.image.style1:after {
    background: #f0f0f0;
    border-radius: 100%;
    content: '';
    display: block;
    height: 0.75em;
    margin: -0.5em 0.5em 0 0;
    position: absolute;
    right: -2em;
    top: 50%;
    width: 0.75em;
}
.image.style2 {
    background: rgba(144, 144, 144, 0.2);
    border-radius: 100%;
    overflow: hidden;
}
.image.style2 img {
    border-radius: 100%;
}

/* List */

ol {
    list-style: decimal;
    margin: 0 0 2em 0;
    padding-left: 1.25em;
}
ol li {
    padding-left: 0.25em;
}
ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
}
ul li {
    padding-left: 0.5em;
}
ul.alt {
    list-style: none;
    padding-left: 0;
}
ul.alt li {
    border-top: solid 1px rgba(144, 144, 144, 0.25);
    padding: 0.5em 0;
}
ul.alt li:first-child {
    border-top: 0;
    padding-top: 0;
}
ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
}
ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
}
ul.icons li:last-child {
    padding-right: 0 !important;
}
ul.icons li .icon:before {
    font-size: 2em;
}
ul.major-icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
    margin-bottom: 1em;
}
ul.major-icons li {
    display: inline-block;
    padding: 0 2.5em 0 0;
}
ul.major-icons li:last-child {
    padding-right: 0 !important;
}
ul.major-icons li .icon {
    display: block;
    font-size: 0.675em;
    height: 4.5em;
    line-height: 4.5em;
    position: relative;
    text-align: center;
    width: 4.5em;
    color: #444 !important;
}
ul.major-icons li .icon:before {
    color: #ffffff;
    font-size: 2em;
    position: relative;
    z-index: 1;
}
ul.major-icons li .icon:after {
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #95D253;
    content: '';
    height: inherit;
    left: 0;
    line-height: inherit;
    position: absolute;
    top: 0;
    width: inherit;
}
ul.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;
}
ul.actions li {
    display: inline-block;
    padding: 0 1em 0 0;
    vertical-align: middle;
}
ul.actions li:last-child {
    padding-right: 0;
}
ul.actions.small li {
    padding: 0 0.5em 0 0;
}
ul.actions.vertical li {
    display: block;
    padding: 1em 0 0 0;
}
ul.actions.vertical li:first-child {
    padding-top: 0;
}
ul.actions.vertical li > * {
    margin-bottom: 0;
}
ul.actions.vertical.small li {
    padding: 0.5em 0 0 0;
}
ul.actions.vertical.small li:first-child {
    padding-top: 0;
}
ul.actions.fit {
    display: table;
    margin-left: -1em;
    padding: 0;
    table-layout: fixed;
    width: calc(100% + 1em);
}
ul.actions.fit li {
    display: table-cell;
    padding: 0 0 0 1em;
}
ul.actions.fit li > * {
    margin-bottom: 0;
}
ul.actions.fit.small {
    margin-left: -0.5em;
    width: calc(100% + 0.5em);
}
ul.actions.fit.small li {
    padding: 0 0 0 0.5em;
}
dl {
    margin: 0 0 2em 0;
}

/* Table */

.table-wrapper {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}
table {
    margin: 0 0 2em 0;
    width: 100%;
}
table tbody tr {
    border: solid 1px rgba(144, 144, 144, 0.25);
    border-left: 0;
    border-right: 0;
}
table tbody tr:nth-child(2n + 1) {
    background-color: rgba(144, 144, 144, 0.075);
}
table td {
    padding: 0.75em 0.75em;
}
table th {
    color: #7f7f7f;
    font-size: 0.9em;
    font-weight: 600;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
}
table thead {
    border-bottom: solid 2px rgba(144, 144, 144, 0.25);
}
table tfoot {
    border-top: solid 2px rgba(144, 144, 144, 0.25);
}
table.alt {
    border-collapse: separate;
}
table.alt tbody tr td {
    border: solid 1px rgba(144, 144, 144, 0.25);
    border-left-width: 0;
    border-top-width: 0;
}
table.alt tbody tr td:first-child {
    border-left-width: 1px;
}
table.alt tbody tr:first-child td {
    border-top-width: 1px;
}
table.alt thead {
    border-bottom: 0;
}
table.alt tfoot {
    border-top: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
.button {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: transparent;
    border: 0;
    border: solid 2px #7f7f7f;
    border-radius: 0 !important;
    color: #7f7f7f !important;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    height: 3em;
    height: calc(3em + 2px);
    letter-spacing: 0.05em;
    line-height: 3em;
    padding: 0 1.25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:hover {
    background-color: rgba(144, 144, 144, 0.075);
}
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
.button:active {
    background-color: rgba(144, 144, 144, 0.2);
}
input[type="submit"].icon,
input[type="reset"].icon,
input[type="button"].icon,
.button.icon {
    padding: 0 1.25em 0 1.35em;
}
input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
.button.icon:before {
    margin: 0 0.5em 0 0;
}
input[type="submit"].icon-after,
input[type="reset"].icon-after,
input[type="button"].icon-after,
.button.icon-after {
    padding: 0 1.35em 0 1.25em;
}
input[type="submit"].icon-after:before,
input[type="reset"].icon-after:before,
input[type="button"].icon-after:before,
.button.icon-after:before {
    margin: 0 0 0 0.75em;
    float: right;
}
input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
.button.fit {
    display: block;
    margin: 0 0 1em 0;
    width: 100%;
}
input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
.button.small {
    font-size: 0.8em;
}
input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
.button.big {
    font-size: 1.35em;
}
input[type="submit"].special,
input[type="reset"].special,
input[type="button"].special,
.button.special {
    background-color: #95D253;
    border: 0;
    color: #ffffff !important;
    height: 3em;
}
input[type="submit"].special:hover,
input[type="reset"].special:hover,
input[type="button"].special:hover,
.button.special:hover {
    background-color: #8b1b8b !important;
}
input[type="submit"].special:active,
input[type="reset"].special:active,
input[type="button"].special:active,
.button.special:active {
    background-color: #8b1b8b !important;
}
input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
.button.disabled,
.button:disabled {
    background-color: #868686 !important;
    border: 0;
    color: #f5f5f5 !important;
    cursor: default;
    height: 3em;
    opacity: 0.25;
}

/* Feature */

.feature {
    margin: 0 0 2em 0;
    padding-left: 7.5em;
    position: relative;
    text-align: left;
}
.feature:before {
    background: rgba(144, 144, 144, 0.25);
    content: '';
    height: 100%;
    left: 5.5em;
    position: absolute;
    top: 0;
    width: 1px;
}
.feature .icon.major {
    font-size: 0.675em;
    left: 0;
    position: absolute;
    top: 0;
}
.feature p {
    margin: 0;
}

/* Tree */

.tree {
    margin: 1em 0 0 0;
    padding: 3em 0 0 0;
    position: relative;
}
.tree:before {
    background: rgba(144, 144, 144, 0.25);
    content: '';
    display: block;
    height: 3.25em;
    left: 50%;
    margin-left: -0.5px;
    position: absolute;
    top: 1px;
    width: 1px;
}
.tree:after {
    background: rgba(144, 144, 144, 0.25);
    content: '';
    display: block;
    height: 1px;
    left: -4em;
    position: absolute;
    top: 0;
    width: calc(100% + 4em);
}
.tree.first:after {
    left: 50%;
    width: 50%;
}
.tree.last:after {
    width: calc(50% + 4em);
}

/* Wrapper */

.wrapper {
    padding: 3em 0 3em 0;
}
.wrapper:after {
    clear: both;
    content: '';
    display: block;
}
.wrapper.split {
    overflow-x: hidden;
    padding: 0;
}
.wrapper.split .primary {
    padding: 6em 2em 4em 0;
    float: left;
    width: 30%;
}
.wrapper.split .secondary {
    color: #838886;
    float: left;
    width: 70%;
}
.wrapper.split .secondary > section {
    padding: 4em 0 2em 4em;
    color: #868686;
    position: relative;
}
.wrapper.split .secondary > section > * {
    position: relative;
    z-index: 1;
}
.wrapper.split .secondary > section:before {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1000%;
    z-index: 0;
}
.wrapper.split .secondary > section:nth-child(2n):before {
    background: #ffffff;
}
.wrapper.split .secondary > section:nth-child(2n + 1):before {
    background: #f5f5f5;
}
.wrapper.split .secondary > section h1,
.wrapper.split .secondary > section h2,
.wrapper.split .secondary > section h3,
.wrapper.split .secondary > section h4,
.wrapper.split .secondary > section h5,
.wrapper.split .secondary > section h6 {
    color: #7f7f7f;
}
.wrapper.split .secondary > section strong,
.wrapper.split .secondary > section b {
    color: #7f7f7f;
}
.wrapper.split .secondary > section header p {
    color: #A5A5a5;
}
.wrapper.split .secondary > section input[type="submit"],
.wrapper.split .secondary > section input[type="reset"],
.wrapper.split .secondary > section input[type="button"],
.wrapper.split .secondary > section .button {
    border-color: #7f7f7f;
    color: #7f7f7f;
}
.wrapper.split .secondary > section input[type="submit"]:hover,
.wrapper.split .secondary > section input[type="reset"]:hover,
.wrapper.split .secondary > section input[type="button"]:hover,
.wrapper.split .secondary > section .button:hover {
    background-color: rgba(144, 144, 144, 0.075);
}
.wrapper.split .secondary > section input[type="submit"]:active,
.wrapper.split .secondary > section input[type="reset"]:active,
.wrapper.split .secondary > section input[type="button"]:active,
.wrapper.split .secondary > section .button:active {
    background-color: rgba(144, 144, 144, 0.2);
}
.wrapper.split .secondary > section .image.style2 {
    background: rgba(144, 144, 144, 0.075);
}
.wrapper.style1 {
    background-color: #95D253;
    background-image: url(../images/green-bg.jpg);
    color: #fff;
}
.wrapper.style1.with-sofia-dating-sidebar {
    background-image: none;
    background: #3cc3cb;
}
.wrapper.style1.split .secondary > section h1,
.wrapper.style1.split .secondary > section h2,
.wrapper.style1.split .secondary > section h3,
.wrapper.style1.split .secondary > section h4,
.wrapper.style1.split .secondary > section h5,
.wrapper.style1.split .secondary > section h6 {
    color: #8b1b8b;
}
.wrapper.style1 h1,
.wrapper.style1 h2,
.wrapper.style1 h3,
.wrapper.style1 h4,
.wrapper.style1 h5,
.wrapper.style1 h6,
.wrapper.style1 strong,
.wrapper.style1 b {
    color: #ffffff;
}
.wrapper.style1 header p {
    color: #fcd1bf;
}
.wrapper.style1 input[type="submit"],
.wrapper.style1 input[type="reset"],
.wrapper.style1 input[type="button"],
.wrapper.style1 .button {
    border-color: #ffffff;
    color: #ffffff !important;
}
.wrapper.style1 input[type="submit"]:hover,
.wrapper.style1 input[type="reset"]:hover,
.wrapper.style1 input[type="button"]:hover,
.wrapper.style1 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}
.wrapper.style1 input[type="submit"]:active,
.wrapper.style1 input[type="reset"]:active,
.wrapper.style1 input[type="button"]:active,
.wrapper.style1 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}
.wrapper.style1 .image.style2 {
    background: rgba(255, 255, 255, 0.2);
}
.wrapper.style1 blockquote.testimonial p {
    background-color: rgba(0, 0, 0, 0.25);
}
.wrapper.style1 blockquote.testimonial p:after {
    border-top-color: rgba(0, 0, 0, 0.25);
}
.wrapper.style1 blockquote.testimonial cite .name {
    color: #ffffff;
}
.wrapper.style1 blockquote.testimonial cite .title {
    color: #fcd1bf;
}
.wrapper.style1 .tree:before {
    background: #ffffff;
}
.wrapper.style1 .tree:after {
    background: #ffffff;
}
.wrapper.style1 .icon.major:before {
    color: #95D253;
}
.wrapper.style1 .icon.major:after {
    background: #ffffff;
}
.wrapper.style2 {
    background-color: #f5f5f5;
}
.wrapper.style3 {
    background-color: #ffffff;
}
.wrapper.style4 {
    background-color: #7b667b;
    color: #e0dbe0;
}
.wrapper.style4.split .secondary > section h1,
.wrapper.style4.split .secondary > section h2,
.wrapper.style4.split .secondary > section h3,
.wrapper.style4.split .secondary > section h4,
.wrapper.style4.split .secondary > section h5,
.wrapper.style4.split .secondary > section h6 {
    color: #8b1b8b;
}
.wrapper.style4 h1,
.wrapper.style4 h2,
.wrapper.style4 h3,
.wrapper.style4 h4,
.wrapper.style4 h5,
.wrapper.style4 h6,
.wrapper.style4 strong,
.wrapper.style4 b {
    color: #ffffff;
}
.wrapper.style4 header p {
    color: #cec5ce;
}
.wrapper.style4 input[type="submit"],
.wrapper.style4 input[type="reset"],
.wrapper.style4 input[type="button"],
.wrapper.style4 .button {
    border-color: #ffffff;
    color: #ffffff !important;
}
.wrapper.style4 input[type="submit"]:hover,
.wrapper.style4 input[type="reset"]:hover,
.wrapper.style4 input[type="button"]:hover,
.wrapper.style4 .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}
.wrapper.style4 input[type="submit"]:active,
.wrapper.style4 input[type="reset"]:active,
.wrapper.style4 input[type="button"]:active,
.wrapper.style4 .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}
.wrapper.style4 .image.style2 {
    background: rgba(255, 255, 255, 0.075);
}
.wrapper.style4 blockquote.testimonial p {
    background-color: rgba(0, 0, 0, 0.25);
}
.wrapper.style4 blockquote.testimonial p:after {
    border-top-color: rgba(0, 0, 0, 0.25);
}
.wrapper.style4 blockquote.testimonial cite .name {
    color: #ffffff;
}
.wrapper.style4 blockquote.testimonial cite .title {
    color: #cec5ce;
}
.wrapper.style4 .tree:before {
    background: #ffffff;
}
.wrapper.style4 .tree:after {
    background: #ffffff;
}
.wrapper.style4 .icon.major:before {
    color: #8b1b8b;
}
.wrapper.style4 .icon.major:after {
    background: #ffffff;
}

/* Header */

#page-wrapper {
    padding-top: 9em;
}
#header {
    background: #8b1b8b;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.125);
    color: #e0dbe0;
    height: 9em;
    left: 0;
    line-height: 3em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
}
#header .container {
    position: relative;
}
#header .icon:before {
    color: #95D253;
}
#header h1,
#header h2,
#header h3,
#header h4,
#header h5,
#header h6,
#header strong,
#header b {
    color: #ffffff;
}
#header h1 {
    font-size: 1.25em;
    height: inherit;
    left: 0;
    line-height: inherit;
    position: absolute;
    top: 0;
}
#header .logo {
    max-height: 60px;
    padding-top: 5px;
    display: none;
}
#header h1 a.icon:before {
    margin-right: 0.5em;
}
#header nav {
    height: inherit;
    line-height: inherit;
    position: absolute;
    right: 0;
    top: 0;
}
#header nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#header nav > ul > li {
    -moz-transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    display: inline-block;
    margin: 0 0.5em;
    padding: 0;
}
#header nav > ul > li > a {
    color: #ffffff;
    display: block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 2.5em;
    padding: 0 1em;
    text-decoration: none;
    text-transform: uppercase;
}
#header nav > ul > li > a.icon:before {
    margin-right: 0.5em;
}
#header nav > ul > li.active {
    background-color: rgba(0, 0, 0, 0.075);
}
#header nav > ul > li.current {
    /*    background-color: rgba(0, 0, 0, 0.25);*/
}
#header nav > ul > li > ul {
    display: none;
}
#header nav > ul > li:last-child {
    margin-right: 0;
}
.dropotron {
    background: #8b1b8b;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.075);
    color: #e0dbe0;
    list-style: none;
    margin-top: -0.5em;
    min-width: 15em;
    padding: 0.5em 0;
}
.dropotron > li {
    box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
    padding: 0;
}
.dropotron > li > a {
    color: #e0dbe0;
    display: block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0.05em;
    line-height: 2.75em;
    padding: 0 1em;
    text-decoration: none;
    text-transform: uppercase;
}
.dropotron > li:hover > a,
.dropotron > li.active > a {
    background-color: #95D253;
    color: #ffffff !important;
}
.dropotron > li:first-child {
    box-shadow: none !important;
}
.dropotron.level-0 {
    font-size: 0.8em;
    margin-top: 3.5em;
}
.dropotron.level-0:before {
    border-bottom: solid 1em #8b1b8b;
    border-left: solid 1em transparent;
    border-right: solid 1em transparent;
    content: '';
    display: block;
    height: 2em;
    left: 50%;
    margin-left: -1em;
    position: absolute;
    top: -2em;
    width: 2em;
}

/* Sofia Leiste */

#sofialeiste {
    text-align: center;
    padding: 0;
    color: #fff;
    font-weight: 600;
    line-height: normal;
    font-size: 1.4em;
}

@media screen and (max-width: 768px) {
    #sofialeiste .phrase {
        padding: 10px;
        background-color: #3cc3cb;
        margin-bottom: 50px;
        min-height: 50px;
    }
}

@media screen and (min-width: 769px) {
    #sofialeiste .phrase {
        /*padding: 10px;*/
        background-color: #3cc3cb;
        /*margin-bottom: 50px;*/
        /*min-height: 50px;*/
    }

    .phrase-one {
        padding-top: 10px;
    }

    .phrase-three {
        padding-bottom: 10px;
    }
}
#sofialeiste::before {
    background-color: #fff;
}

#sofialeiste b {
    color: #fff;
    font-weight: 700;
}

#sofialeiste .red {
  color: #d32c46;
}

#sofialeiste .sofia-light {
  font-weight: normal;
}

/* Sofia Google / Apple Download Buttons */

#sofia-dating-banner.anmelden-button-wrapper .soon-text
#sofialeiste .anmelden-button-wrapper .soon-text {
    display: inline-block;
    font-size: 25px;
    margin-right: 8px;
}

#sofia-dating-banner .anmelden-button,
#sofialeiste .anmelden-button {
    display: inline-block;
    padding: 4px 12px;
    font-size: 19px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background: #C6383A;
}

#sofia-dating-banner .anmelden-button-wrapper.sofiadating .anmelden-button,
#sofialeiste .anmelden-button-wrapper.sofiadating .anmelden-button {
    text-transform: none;
    border-radius: 12px;
    font-size: 15px;
    vertical-align: middle;
    cursor: default;
}

.sofiadating-sidebar .download-button,
#sofialeiste .download-button {
    display: inline-block;
    margin: 8px 10px;
    vertical-align: middle;
}

.sofiadating-sidebar .download-button img,
#sofialeiste .download-button img {
    display: block;
    height: 32px;
    width: auto !important;
}

/* Sofia Dating Banner */

#sofia-dating-banner {
	font-family: Montserrat,Helvetica,sans-serif;
    font-size: 17px;
    line-height: 1.3;
    color: #4ca6b6;
    display: flex;
    margin: 0;
}

#sofia-dating-banner .banner-content {
    background: #f5f5f5;
    text-align: center;
}

#sofia-dating-banner h2 {
    font-weight: 800;
    font-size: 30px;
}

#sofia-dating-banner h3 {
    text-align: center;
    font-size: 28px;
    line-height: 1.1;
	font-weight: 800;
    letter-spacing: 0.1em;
    margin: 0 0 20px 0;
}
#sofia-dating-banner h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}
#sofia-dating-banner p {
    font-size: 22px;
    margin: 3px 0;
	font-weight: 400;
}
#sofia-dating-banner strong {
	font-weight: 600;
}
#sofia-dating-banner article {
    margin: 20px 0;
}
#sofia-dating-banner h4,
#sofia-dating-banner strong {
	color: #4ca6b6;
}
#sofia-dating-banner .red {
    color: #d32c46;
}

#sofia-dating-banner .image-right {
    flex: 2;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

#sofia-dating-banner .image-right .logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
}

#sofia-dating-banner .image-right {
    background-image: url("https://cityspeeddating.at/images/image-2.jpeg");
    background-position: 80% center;
}

#sofia-dating-banner .banner-content {
    padding: 80px 20px;
    flex: 2;
}

#sofia .secondary section {
    padding: 0;
}

#sofia .primary img {
    width: 80%;
    margin: 0 auto;
}

/* Sofia Dating Sidebar Banner */
.wrapper.style1.with-sofia-dating-sidebar .banner-image {
    position: relative;
}

.wrapper.style1.with-sofia-dating-sidebar .banner-image .banner-logo-link {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.wrapper.style1.with-sofia-dating-sidebar h2,
.wrapper.style1.with-sofia-dating-sidebar h3 {
    color: #d32c46;
    font-weight: 600;
}

.wrapper.style1.with-sofia-dating-sidebar .banner-text {
    background: #fff;
    color: #289095;
    padding: 10px;
}

.wrapper.style1.with-sofia-dating-sidebar .check-row {
    padding-left: 31px;
    margin: 5px 0;
    position: relative;
}

.wrapper.style1.with-sofia-dating-sidebar .uk-icon-check {
    color: #63ce00;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.wrapper.style1.with-sofia-dating-sidebar .text-center {
    text-align: center;
}


/* Banner */

#banner {
    /*    padding: 9em 0 7em 0;*/
    padding: 0;
    background-color: #423e3a;
    background-image: url("../images/banner.jpg");
    background-position: 50% 20% !important;
    /* Anpassbar */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #cfcecd;
    height: 430px;
}
#banner h1,
#banner h2,
#banner h3,
#banner h4,
#banner h5,
#banner h6,
#banner strong,
#banner b {
    color: #ffffff;
}
#banner header p {
    color: #fff;
    margin-top: 30px;
    text-shadow: 0px 0px 10px rgba(150, 150, 150, 1);
}
#banner input[type="submit"],
#banner input[type="reset"],
#banner input[type="button"] {
    border-color: #ffffff;
    color: #ffffff !important;
}
#banner .button {
    border-color: #8b1b8b;
    color: #8b1b8b !important;
}
#banner input[type="submit"]:hover,
#banner input[type="reset"]:hover,
#banner input[type="button"]:hover,
#banner .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
}
#banner .button:hover {
    text-decoration: none;
}
#banner input[type="submit"]:active,
#banner input[type="reset"]:active,
#banner input[type="button"]:active,
#banner .button:active {
    background-color: rgba(255, 255, 255, 0.2);
}
#banner h2 {
    font-size: 2.25em;
}

/* Footer */

#footer {
    padding: 6em 0 4em 0;
    text-align: center;
}
#footer .icon:before {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    color: #A5A5a5;
}
#footer .icon:after {
    -moz-transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    background-color: rgba(144, 144, 144, 0.2);
}
#footer .icon:hover:before {
    color: #ffffff;
}
/*
#footer .icon:hover:after {
    background-color: #95D253;
}
*/

/* Button Hover Box */

.uk-badge.uk-badge-purple.uk-badge-small {
    position: relative;
    cursor: default;
}

.uk-badge.uk-badge-purple.uk-badge-small .hover-box {
    position: absolute;
    z-index: 500;
    width: 200px;
    padding: 4px;
    border: 1px solid #8b1b8b;
    background: #efefef;
    color: #8b1b8b;
    text-shadow: none;
    line-height: 1.3;
    white-space: normal;
}

/* XLarge !!! */


/*@media screen and (max-width: 1680px) { */

@media screen and (min-width: 1281px) {
    body,
    input,
    select,
    textarea {
        font-size: 11pt;
    }
}

/* Large */

@media screen and (max-width: 1280px) {
    /* Basic */
    body,
    input,
    select,
    textarea {
        font-size: 11pt;
    }
    /* Tree */
    .tree:after {
        left: -3em;
        width: calc(100% + 3em);
    }
    .tree.last:after {
        width: calc(50% + 3em);
    }
    /* Wrapper */
    .wrapper {
        padding: 2em 0 2em 0;
    }
    .wrapper.split .primary {
        padding: 3em 3em 1em 0;
    }
    .wrapper.split .secondary > section {
        padding: 3em 0 1em 3em;
    }
    /* Header */
    #page-wrapper {
        padding-top: 8.5em;
    }
    #header {
        height: 8.5em;
        line-height: 2.6em;
    }
    #header h1 {
        font-size: 1em;
    }
    #header nav {
        font-size: 0.9em;
    }
    .dropotron.level-0 {
        font-size: 0.9em;
    }
    /* Banner */
    #banner {
        padding: 0;
    }
    /* Footer */
    #footer {
        padding: 4em 0 2em 0;
    }
}

/* tablet */
@media screen and (max-width: 1025px) {
    /* Basic */
    html,
    body {
        overflow-x: hidden;
    }
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
    .textleft {
        text-align: left !important;
    }
    blockquote.testimonial {
        margin-top: 0;
    }
    blockquote.testimonial p {
        max-width: 30em;
        font-size: 1.2em;
    }
    /* Wrapper */
    .wrapper.split .primary {
        padding: 3em 0 1em 0;
        float: none;
        width: 100%;
    }
    .wrapper.split .secondary {
        float: none;
        width: 100%;
    }
    .wrapper.split .secondary > section {
        padding: 2em;
        width: 100%;
    }
    .wrapper.split .secondary > section:before {
        width: 100%;
    }
    /* Header */
    #page-wrapper {
        padding-top: 6em;
    }
    #header {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        height: 3em;
        line-height: 3em;
    }
    #header .container {
        width: 100% !important;
    }
    #header h1 {
        margin-left: 1em;
    }
    #header nav {
        display: none;
    }
    /* Banner */
    #banner {
        padding: 0;
    }
    #banner h2 {
        font-size: 2em;
    }
    /* Off-Canvas Navigation */
    .loginButton,
    .logoutButton {
        display: none !important;
    }
    #page-wrapper {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        padding-bottom: 1px;
    }
    #navButton {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        display: block;
        height: 3em;
        right: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10001;
    }
    #navButton .toggle {
        text-decoration: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    #navButton .toggle:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-transform: none !important;
    }
    #navButton .toggle:before {
        background: #8b1b8b;
        color: #fff;
        content: '\f0c9';
        display: block;
        font-size: 16px;
        height: 3em;
        line-height: 3em;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 25px;
        width: 4em;
    }
    #navPanel {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -moz-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -ms-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        -moz-transform: translateY(-25.4em);
        -webkit-transform: translateY(-25.4em);
        -ms-transform: translateY(-25.4em);
        transform: translateY(-25.4em);
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        background-color: #8b1b8b;
        color: #e0dbe0;
        display: block;
        height: 25.4em;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10002;
    }
    #navPanel .link {
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
        color: #e0dbe0;
        display: block;
        font-family: "Montserrat", Helvetica, sans-serif;
        font-size: 0.8em;
        font-weight: 400;
        letter-spacing: 0.05em;
        line-height: 4em;
        padding: 0 1.5em;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
    }
    #navPanel .link:first-child {
        box-shadow: none;
    }
    #navPanel .link.depth-0 {
        color: #ffffff;
    }
    #navPanel .link .indent-1 {
        display: inline-block;
        width: 1.25em;
    }
    #navPanel .link .indent-2 {
        display: inline-block;
        width: 2.5em;
    }
    #navPanel .link .indent-3 {
        display: inline-block;
        width: 3.75em;
    }
    #navPanel .link .indent-4 {
        display: inline-block;
        width: 5em;
    }
    #navPanel .link .indent-5 {
        display: inline-block;
        width: 6.25em;
    }
    html.navPanel-visible {
        overflow-y: hidden;
    }
    html.navPanel-visible body {
        overflow-y: hidden;
    }
    html.navPanel-visible body #page-wrapper,
    html.navPanel-visible body #navButton,
    html.navPanel-visible body #header {
        -moz-transform: translateY(25.4em);
        -webkit-transform: translateY(25.4em);
        -ms-transform: translateY(25.4em);
        transform: translateY(25.4em);
    }
    html.navPanel-visible body #navPanel {
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    /* Landing */
    body.landing #banner {
        text-align: center;
    }
    body.landing #one {
        text-align: center;
    }
    body.landing #two {
        text-align: center;
    }
    .gutscheinschenken {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .simpletable {
        text-align: left;
    }
    .simpletable td {
        padding: 2px;
        padding-left: 2.5em;
    }
    ul.buttonsSidebar li {
        display: block !important;
        max-width: 250px;
        margin: 0 auto;
    }
}

/* 07.02.2017 */

@media screen and (min-width: 981px) {
    .row.uniform {
        /*        margin: -2em 0 -1px -2em;*/
        margin: 1.5em 0 -1px -2em !important;
        /* !! Geändert 07.02.2017 */
    }
    .cities {
        width: 120px;
        text-align: center;
    }
    .cities label {
        width: 100%;
    }
}

/* Medium */

/*#navPanel,*/
/*#navButton {*/
/*    display: none;*/
/*}*/
@media screen and (max-width: 980px) {
    /* Basic */
    html,
    body {
        overflow-x: hidden;
    }
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
    .textleft {
        text-align: left !important;
    }
    blockquote.testimonial {
        margin-top: 0;
    }
    blockquote.testimonial p {
        max-width: 30em;
        font-size: 1.2em;
    }
    /* Wrapper */
    .wrapper.split .primary {
        padding: 3em 0 1em 0;
        float: none;
        width: 100%;
    }
    .wrapper.split .secondary {
        float: none;
        width: 100%;
    }
    .wrapper.split .secondary > section {
        padding: 2em;
        width: 100%;
    }
    .wrapper.split .secondary > section:before {
        width: 100%;
    }
    /* Header */
    #page-wrapper {
        padding-top: 6em;
    }
    #header {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        height: 3em;
        line-height: 3em;
    }
    #header .container {
        width: 100% !important;
    }
    #header h1 {
        margin-left: 1em;
    }
    #header nav {
        display: none;
    }
    /* Banner */
    #banner {
        padding: 0;
    }
    #banner h2 {
        font-size: 2em;
    }
    /* Off-Canvas Navigation */
    .loginButton,
    .logoutButton {
        display: none !important;
    }
    #page-wrapper {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        padding-bottom: 1px;
    }
    #navButton {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        display: block;
        height: 3em;
        right: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10001;
    }
    #navButton .toggle {
        text-decoration: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    #navButton .toggle:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-transform: none !important;
    }
    #navButton .toggle:before {
        background: #8b1b8b;
        color: #fff;
        content: '\f0c9';
        display: block;
        font-size: 16px;
        height: 3em;
        line-height: 3em;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 25px;
        width: 4em;
    }
    #navPanel {
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -moz-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), -ms-linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        background-image: linear-gradient(top, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), linear-gradient(top, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.1));
        -moz-transform: translateY(-25.4em);
        -webkit-transform: translateY(-25.4em);
        -ms-transform: translateY(-25.4em);
        transform: translateY(-25.4em);
        -moz-transition: -moz-transform 0.5s ease;
        -webkit-transition: -webkit-transform 0.5s ease;
        -ms-transition: -ms-transform 0.5s ease;
        transition: transform 0.5s ease;
        background-color: #8b1b8b;
        color: #e0dbe0;
        display: block;
        height: 25.4em;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10002;
    }
    #navPanel .link {
        box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
        color: #e0dbe0;
        display: block;
        font-family: "Montserrat", Helvetica, sans-serif;
        font-size: 0.8em;
        font-weight: 400;
        letter-spacing: 0.05em;
        line-height: 4em;
        padding: 0 1.5em;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
    }
    #navPanel .link:first-child {
        box-shadow: none;
    }
    #navPanel .link.depth-0 {
        color: #ffffff;
    }
    #navPanel .link .indent-1 {
        display: inline-block;
        width: 1.25em;
    }
    #navPanel .link .indent-2 {
        display: inline-block;
        width: 2.5em;
    }
    #navPanel .link .indent-3 {
        display: inline-block;
        width: 3.75em;
    }
    #navPanel .link .indent-4 {
        display: inline-block;
        width: 5em;
    }
    #navPanel .link .indent-5 {
        display: inline-block;
        width: 6.25em;
    }
    html.navPanel-visible {
        overflow-y: hidden;
    }
    html.navPanel-visible body {
        overflow-y: hidden;
    }
    html.navPanel-visible body #page-wrapper,
    html.navPanel-visible body #navButton,
    html.navPanel-visible body #header {
        -moz-transform: translateY(25.4em);
        -webkit-transform: translateY(25.4em);
        -ms-transform: translateY(25.4em);
        transform: translateY(25.4em);
    }
    html.navPanel-visible body #navPanel {
        -moz-transform: translateY(0);
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    /* Landing */
    body.landing #banner {
        text-align: center;
    }
    body.landing #one {
        text-align: center;
    }
    body.landing #two {
        text-align: center;
    }
    .gutscheinschenken {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }
    .simpletable {
        text-align: left;
    }
    .simpletable td {
        padding: 2px;
        padding-left: 2.5em;
    }
    ul.buttonsSidebar li {
        display: block !important;
        max-width: 250px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 980px) and (max-height: 480px) {
    #navPanel {
        -moz-transform: translateY(-13em);
        -webkit-transform: translateY(-13em);
        -ms-transform: translateY(-13em);
        transform: translateY(-13em);
        height: 13em;
    }
    html.navPanel-visible body #page-wrapper,
    html.navPanel-visible body #navButton,
    html.navPanel-visible body #header {
        -moz-transform: translateY(13em);
        -webkit-transform: translateY(13em);
        -ms-transform: translateY(13em);
        transform: translateY(13em);
    }
}

/* Small */

@media screen and (max-width: 736px) {
    /* Basic */
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
    h1 br,
    h2 br,
    h3 br,
    h4 br,
    h5 br,
    h6 br {
        display: none;
    }
    h2 {
        font-size: 1.35em;
    }
    h3 {
        font-size: 1.1em;
    }
    h4 {
        font-size: 1em;
    }
    /* Section/Article */
    header p br {
        display: none;
    }
    header h2 + p {
        font-size: 1.1em;
    }
    header h3 + p {
        font-size: 1em;
    }
    /* Icon */
    .icon.major {
        height: 3.75em;
        line-height: 3.75em;
        width: 3.75em;
    }
    .icon.major:before {
        font-size: 1.75em;
    }
    /* Image */
    .image.style1 {
        border-width: 0.75em;
    }
    .image.style1:after {
        display: none;
    }
    /* List */
    ul.major-icons li {
        padding-right: 1.5em;
    }
    ul.major-icons li .icon {
        font-size: 0.5em;
        color: #444;
    }
    /* Feature */
    .feature {
        padding-left: 5em;
    }
    .feature:before {
        left: 3.75em;
    }
    .feature .icon.major {
        font-size: 0.5em;
    }
    /* Tree */
    .tree {
        margin: 0;
        padding: 0;
    }
    .tree:before,
    .tree:after {
        display: none;
    }
    /* Header */
    #page-wrapper {
        padding-top: 6em;
    }
    #header {
        height: 44px;
        line-height: 44px;
    }
    #header h1 {
        font-size: 0.9em;
    }
    /* Banner */
    #banner {
        padding: 0;
    }
    #banner h2 {
        font-size: 1.5em;
    }
    /* Off-Canvas Navigation */
    #navButton .toggle:before {
        height: 40px;
        line-height: 40px;
        width: 40px;
    }
    #navPanel .link {
        line-height: 45px;
        padding: 0 1.25em;
    }
}

/* XSmall */

@media screen and (max-width: 480px) {
    /* Basic */
    html,
    body {
        min-width: 320px;
    }
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
    /* List */
    ul.actions {
        margin: 0 0 2em 0;
    }
    ul.actions li {
        display: block;
        padding: 1em 0 0 0;
        text-align: center;
        width: 100%;
    }
    ul.actions li:first-child {
        padding-top: 0;
    }
    ul.actions li > * {
        margin: 0 !important;
        width: 100%;
    }
    ul.actions li > *.icon:before {
        margin-left: -2em;
    }
    ul.actions.small li {
        padding: 0.5em 0 0 0;
    }
    ul.actions.small li:first-child {
        padding-top: 0;
    }
    /* Button */
    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    .button {
        padding: 0;
    }
    input[type="submit"].icon-after:before,
    input[type="reset"].icon-after:before,
    input[type="button"].icon-after:before,
    .button.icon-after:before {
        display: none;
    }
    /* Banner */
    #banner {
        padding: 0;
    }
    #footer {
        padding: 0 !important;
        margin-bottom: 3em;
        margin-top: 2em !important;
    }
    #presse .image.fit {
        margin: 0;
    }
    #sofialeiste {
      line-height: 1.2em;
    }
	#sofia-dating-banner {
        display: block;
        margin: 70px 0;
    }

    #sofia-dating-banner .image-left,
    #sofia-dating-banner .image-right {
        height: 400px;
        background-position: center center;
    }

    #sofia-dating-banner .image-left .logo,
    #sofia-dating-banner .image-right .logo {
        width: 55%;
    }
    .accordion-heading {
        text-align: left;
    }
    .erfolgSmallBox {
        margin-top: 1em;
    }
    .img-small {
        max-width: 150px !important;
    }
    .thumbs {
        max-width: 100% !important;
    }
    .chatinfoimg {
        margin: 0 !important;
    }
    .uk-overflow-container table tr td:last-child {
        text-align: left !important;
    }
    .row.uniform ul {
        padding-left: 0 !important;
    }
}

/* ToolTips */

.tooltipster-box {
	max-width: 450px;
	border: 3px solid red;
}

.marketingboximg {
	float: left;
	margin-right: 20px;
}

.presselogo_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.presselogo_container .more_link a {
    font-weight: 100;
    text-decoration: none;
    color: rgb(134, 134, 134);
    font-family: "Source Sans Pro", Helvetica, sans-serif;
}
