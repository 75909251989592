/* Magnific Popup CSS */

.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
}

.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
    display: none;
}

.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
}

.mfp-ajax-cur {
    cursor: progress;
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.mfp-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
    cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.mfp-loading.mfp-figure {
    display: none;
}

.mfp-hide {
    display: none !important;
}

.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.mfp-preloader a {
    color: #CCC;
}

.mfp-preloader a:hover {
    color: #FFF;
}

.mfp-s-ready .mfp-preloader {
    display: none;
}

.mfp-s-error .mfp-content {
    display: none;
}

button.mfp-close,
button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
    opacity: 1;
}

.mfp-close:active {
    top: 1px;
}

.mfp-close-btn-in .mfp-close {
    color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.mfp-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.mfp-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
    margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.mfp-arrow-left {
    left: 0;
}

.mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
    top: -40px;
}

.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */

img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */

.mfp-figure {
    line-height: 0;
}

.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.mfp-figure figure {
    margin: 0;
}

.mfp-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.mfp-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.mfp-image-holder .mfp-content {
    max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
       * Remove all paddings around the image on small screen
       */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }

    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }

    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }

    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }

    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        box-sizing: border-box;
    }

    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }

    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }

    .mfp-img-mobile .mfp-close {
        top: 0;
        right: 0;
        width: 35px;
        height: 35px;
        line-height: 35px;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        text-align: center;
        padding: 0;
    }
}

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }

    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }

    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }

    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.thumbs {
    max-height: 225px;
    max-width: 300px !important;
    display: inline-block !important;
    margin-right: 1em;
    margin-bottom: 1em;
}

/* Allgemein */

div.uk-badge:empty,
span.uk-badge:empty {
    display: none;
}

.strong {
    font-weight: 900;
}

/* Unslider */

.unslider {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.unslider-wrap {
    position: relative
}

.unslider-wrap.unslider-carousel li {
    float: left
}

.unslider-vertical > ul {
    height: 100%
}

.unslider-vertical li {
    float: none;
    width: 100%
}

.unslider-fade {
    position: relative
}

.unslider-fade .unslider-wrap li {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 8
}

.unslider-fade .unslider-wrap li.unslider-active {
    z-index: 10
}

.unslider li,
.unslider ol,
.unslider ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: none
}

.unslider-arrow {
    position: absolute;
    left: 20px;
    z-index: 2;
    cursor: pointer
}

.unslider-arrow.next {
    left: auto;
    right: 20px
}

/* Lightbox */

#imagelightbox {
    position: fixed;
    z-index: 9999;
    -ms-touch-action: none;
    touch-action: none;
}

/* Slides */

.sliderTest {
    width: 90%;
    margin-left: 5%;
    height: 100%;
    color: #fff;
    text-shadow: 0px 0px 14px rgba(0, 0, 0, 1);
}

.sliderTest div {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}



.sliderTest div span {
    font-size: 2em;
    line-height: 90%;
}

.unslider {
    position: relative;
}

.slide {
    height: 430px;
    box-sizing: border-box;
}

.slide table tr {
    background-color: transparent !important;
    border: 0 !important;
}

.slide table td {
    vertical-align: middle;
}

.slide .left {
    width: 30%;
    height: 100%;
    text-align: center;
    margin: 0 0 0 60px;
    text-align: center;
    font-size: 3.5em;
    line-height: normal;
    color: #8b1b8b;
    text-shadow: 0px 0px 14px rgba(255, 255, 255, 1);
}

.slide .right {
    width: 30%;
    right: 0;
    height: 100%;
    text-align: center;
    margin-left: 65%;
    text-align: center;
    font-size: 3.5em;
    line-height: normal;
    /*    font-family: 'Caveat', cursive;*/
    color: #fff;
    text-shadow: 0px 0px 14px #8b1b8b;
}

.f1 {
    font-family: 'Dancing Script', cursive;
}

.f2 {
    font-family: 'Kaushan Script', cursive;
}

.f3 {
    font-family: 'Permanent Marker', cursive;
}

.slide .bottom {
    width: 100%;
    height: 30%;
    background-color: blue;
    text-align: center;
    margin-top: 70%;
}

.unslider-arrow {
    width: 24px;
    height: 44px;
    background-color: crimson;
    margin-top: -22px;
    top: 50%;
    background: url("../images/sliderArrow.png") no-repeat center center;
    text-indent: -999em;
    opacity: .6;
    transition: opacity .2s;
}

.unslider-arrow:hover {
    opacity: 1;
    transition: opacity .2s;
}

.unslider .next {
    transform: rotate(180deg);
}

.text-1 {
    height: 100%;
    width: 30%;
    margin-left: 60px;
    background-color: green;
}

.block {
    display: block;
}

.vorteileslogan,
.vorteileslogan h2 {
    padding-top: 0 !important;
}

/* ################### */


/* ###### FONT ####### */


/* ################### */

.handwritten,
.h2Handwritten {
    font-family: 'Satisfy', cursive;
}

.primary .handwritten {
    font-size: 1.5em;
}

.h2Handwritten {
    font-size: 2em;
}

.erfolgsstories p {
    font-size: 1.3em;
}

.csd-center {
    text-align: center;
}

.csd-right {
    text-align: right;
}

.smallLink {
    font-size: 0.8em;
    color: #7f7f7f;
    text-decoration: underline;
}

.smallLink:hover {
    color: #8b1b8b;
}

/* ################## */


/* ###### NAV ####### */


/* ################## */

#topNav {
    width: 100%;
    height: 6em;
    display: block;
    background-color: #fff;
}

.loginButton {
    -moz-transition: background-color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
    display: inline-block;
    margin: 25px 0 0 0;
    padding: 0;
}

.loginButton,
.loginButton > a {
    color: #ffffff;
    display: inline-block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 2.5em;
    padding: 0 1em;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #8b1b8b;
    /*    width: 73.25px;*/
    width: auto;
    font-size: 10px;
    text-align: center;
}

.logoutButton,
.logoutButton > a {
    color: #8b1b8b;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 2.3em;
    padding: 0 1em;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #8B1B9B;
    width: auto;
    font-size: 10px;
    text-align: center;
    border-radius: 0;
}

.filterButton {
    display: inline-block;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 2.5em;
    padding: 0 1em;
    text-decoration: none;
    text-transform: uppercase;
    color: #8b1b8b;
    width: auto;
    font-size: 10px;
    text-align: center;
    border-radius: 0% !important;
}

.filterButton {
    margin: 1em 0;
    font-size: 100%;
}

#logoTop {
    background-color: #fff;
    padding: 10px 50px;
    box-sizing: border-box;
    height: 6em;
}

#loginMenu {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
}

.navWrap {
    width: auto;
    margin-right: 20px;
}

#header nav > ul > li.chat-menu-button {
    background-color: #95D253;
}

/* ####################### */


/* ##### CHATLEISTE ###### */


/* ####################### */

.chat-wrapper {
    padding: 0.2em 0 0.2em 0;
    background-color: #d32c46;
    color: #fff;
    letter-spacing: 0.1em;
    text-align: center;
}

.chat-wrapper strong {
    color: #fff;
    text-decoration: underline;
}

.chatAd {
    margin: 0 auto !important;
}

@media screen and (max-width: 980px) {
    .image.fit img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }

    .chatAd {
        padding-top: 2em !important;
    }
}

.selectcommuniction {
    background-color: #95D253;
    padding: 2em;
    text-align: left;
}

.selectcommuniction * {
    color: #fff !important;
}

.selectcommuniction input[type=checkbox] + label:before {
    background: rgba(144, 144, 144, .2) !important;
    border: 1px solid rgba(144, 144, 144, 0.7) !important;
}

/* ####################### */


/* ##### PRESSE ###### */


/* ####################### */

.presse-wrapper {
    padding: 0.5em 0 0.5em 0;
    background-color: #fff;
}

.presselogos {
    margin: 0 !important;
}

@media screen and (max-width: 980px) {
    .presselogos img {
        width: 90% !important;
        margin: 0 auto;
    }
}

@media screen and (min-width: 981px) {
    .presselogos img {
        width: 50% !important;
        margin: 0 auto;
    }
}

.mehrpresse {
    text-align: center;
    margin: 0 auto;
    display: block;
    font-size: 0.8em;
    color: #444;
}

/* ####################### */


/* ##### GRID SPEZ. ###### */


/* ####################### */

.wrapper.split .primary {
    padding: 0em 4em 4em 0;
}

/* Weggenommen !!! eventuell @media einbauen
.wrapper.split .secondary > section {
    padding: 1.5em 0 2em 4em;
}
*/


/* Durch Austauschen zweier DIVs muss das Float entsprechend angepasst werden. So wird es denn am Handy in der anderen Reihenfolge angezeigt */

.reverseFloat {
    float: right !important;
    margin-bottom: 1em !important;
}

@media screen and (max-width: 980px) {
    .wrapper.split .primary {
        padding: 2em;
    }
}

/* ################### */


/* ##### KREISE ###### */


/* ################### */


/* Kreise rund machen */

.uk-badge {
    min-width: 20px;
    min-height: 20px;
    box-sizing: border-box;
}

/* #################### */


/* ##### BUTTONS ###### */


/* #################### */

.uk-badge-purple {
    background-color: #9637ad;
    color: #fff;
    background-image: -webkit-linear-gradient(top, #bc64d2, #9637ad);
    background-image: linear-gradient(to bottom, #bc64d2, #9637ad);
    border-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .4);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
}

.uk-badge-grey {
    -webkit-appearance: none;
    margin: 0;
    border: none;
    font: inherit;
    color: #444;
    text-transform: none;
    background: #f7f7f7;
    border: 1px solid rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .3);
    background-origin: border-box;
    background-image: -webkit-linear-gradient(top, #fff, #eee);
    background-image: linear-gradient(to bottom, #fff, #eee);
    border-radius: 4px;
    text-shadow: 0 1px 0 #fff;
}

.uk-badge-small {
    min-height: 25px;
    padding: 0 10px;
    line-height: 23px;
    font-size: 12px;
    border-radius: 4px;
    font-weight: normal;
}

.uk-button-purple {
    background-color: #9637ad;
    color: #fff;
    background-image: -webkit-linear-gradient(top, #bc64d2, #9637ad);
    background-image: linear-gradient(to bottom, #bc64d2, #9637ad);
    border: 1px solid rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    text-shadow: none;
}

.uk-button-purple:hover,
.uk-button-purple:active {
    background-image: -webkit-linear-gradient(top, #9637ad, #bc64d2);
    background-image: linear-gradient(to bottom, #9637ad, #bc64d2);
    color: #fff;
    text-shadow: none;
}

.uk-button-nohover:hover,
.uk-button-nohover:active {
    background-image: -webkit-linear-gradient(top, #bc64d2, #9637ad) !important;
    background-image: linear-gradient(to bottom, #bc64d2, #9637ad) !important;
    cursor: default;
}

.vorteile {
    padding: 5%;
}

.vorteile table tr {
    background-color: transparent !important;
    border: 0;
}

.vorteile table tr td {
    vertical-align: top;
    text-align: left;
    padding: 0;
}

.vorteile .row.uniform p {
    margin-bottom: 0;
    margin-left: 30px;
}

.vorteile table tr td h3 {
    margin-bottom: 0;
    margin-left: 30px;
}

.vorteilebilder {
    width: 120px;
    border-right: 1px solid rgba(144, 144, 144, 0.25);
    padding: 0 0 0 0.75em;
}

.vorteilebilder img {
    width: 100px;
}

.vorteilebilder a {
    cursor: pointer;
}

@media screen and (min-width: 1026px) {
    .sliderTest div span {
        font-size: 3em;
        line-height: 90%;
    }

    #navPanel {
        display: none;
    }

    .mobileChatButtonFirstWord {
        display: block;
        font-size: 0.75em;
    }

    .mobileChatBar {
        width: 75px;
        margin-left: 92%;
        height: 150px;
        position: fixed;
        bottom: -25px;
        z-index: 999;
        text-align: center;
        display: block;
    }

    .mobileChatButton > a {
        color: #ffffff;
        display: block;
        height: 75px;
        font-size: 0.85em;
        font-family: "Montserrat", Helvetica, sans-serif;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 1em;
        padding: 1.8em 0.4em;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #8b1b8b;
        border: solid 1px #8b1b8b;
        border-radius: 100px;
    }
}

/* #################### */


/* ##### TABELLE ###### */


/* #################### */

.mobileEventTable,
/*.desktopEventTable,*/
.mobileAdminNavButton,
.mobileLoginButton,
.logoutButtonMobile,
.anmeldungoder {
    display: none;
    margin: 0;
}

.mobileEventTable tr td {
    text-align: left;
}

.eventInfoSidebarTable {
    background-color: #fff;
    color: #8b1b8b;
}

/* Mobile spezifisch */

.mobileAdminNavButton a, .mobileLoginButton a {
    color: #fff !important;
}

@media screen and (max-width: 480px) {
    .mobileNoPadding {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .unslider {
        height: 472px;
        /* Damit die Pfeile in der Mitte bleiben */
    }

    .sliderTest {
        width: 100%;
        margin-left: 0;
        padding: 5%;
        height: 40%;
        padding-top: 50%;
        /* margin-top: 55%; */
        /* 100% - height - padding */
    }

    .mobilew100 {
        width: 100%;
    }

    .mehrpresse {
        max-width: 100% !important;
    }

    #logoTop {
        padding: 10px 20px !important;
        height: 5.5em !important;
        margin-top: 0.25em !important;
    }
}

@media screen and (max-width: 736px) {
    .mobileEventTable {
        display: table;
    }

    .mobileLoginButton {
        height: 40px !important;
        line-height: 40px !important;
        width: 40px !important;
        right: 60px !important;
    }

    .mobileAdminNavButton {
        height: 40px !important;
        line-height: 40px !important;
        width: 40px !important;
        right: 110px !important;
    }

    .anmeldungheading {
        /*        height: 2em;*/
        line-height: 2em;
        margin: 5px;
    }

    .anmeldungheading ~ * {
        display: none;
    }

    .anmeldungoder {
        display: block;
        width: 100%;
    }

    #navButton a {
        width: 40px !important;
        height: 40px !important;
        right: 0 !important;
        left: auto !important;
    }

    #header {
        height: 0 !important;
    }
}

@media screen and (min-width: 737px) {
    .desktopEventTable {
        display: table;
    }
}

@media screen and (max-width: 1025px) {
    .mobileAdminNavButton, .mobileLoginButton {
        display: block;
        height: 3em;
        right: 80px;
        position: fixed;
        top: 25px;
        width: 4em;
        z-index: 10001;
        text-align: center;
        color: #fff;
        background-color: #95D253;
    }

    .mobileAdminNavButton {
        right: 150px;
    }

    .logoutButton {
        display: none;
    }

    .logoutButtonMobile {
        display: inline-block;
    }

    .mobileChatBar {
        width: 100%;
        height: 40px;
        position: fixed;
        bottom: 0;
        z-index: 999;
        text-align: center;
        display: block;
    }

    .mobileChatButton > a {
        color: #ffffff;
        display: block;
        font-family: "Montserrat", Helvetica, sans-serif;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 2.5em;
        padding: 0 1em;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #8b1b8b;
    }
}

@media screen and (max-width: 980px) {
    .mobileAdminNavButton, .mobileLoginButton {
        display: block;
        height: 3em;
        right: 80px;
        position: fixed;
        top: 25px;
        width: 4em;
        z-index: 10001;
        text-align: center;
        color: #fff;
        background-color: #95D253;
    }

    .mobileAdminNavButton {
        right: 150px;
    }

    .logoutButton {
        display: none;
    }

    .logoutButtonMobile {
        display: inline-block;
    }

    .mobileChatBar {
        width: 100%;
        height: 40px;
        position: fixed;
        bottom: 0;
        z-index: 999;
        text-align: center;
        display: block;
    }

    .mobileChatButton > a {
        color: #ffffff;
        display: block;
        font-family: "Montserrat", Helvetica, sans-serif;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 2.5em;
        padding: 0 1em;
        text-decoration: none;
        text-transform: uppercase;
        background-color: #8b1b8b;
    }
}

/* Fehlermeldungen */

.alert ul li {
    color: white !important;
    font-weight: bold !important;
    background-color: red !important;
    padding: 0.5em !important;
}


/* Städte-Leiste */

.event-filter-home {
    padding-bottom: 1em;
}

.event-filter-home button,
.event-filter-home a {
    margin-right: 10px;
    margin-top: 10px;
}

.event-filter-home button:last-child {
    margin-left: 50px;
}

@media screen and (max-width: 480px) {
    .event-filter-home button:last-child {
        margin-left: 0;
    }
}

/* Tabelle selbst */

.uk-overflow-container table tr td:last-child {
    text-align: right;
}

/*
/* ###################### */


/* ##### ANMELDUNG ###### */


/* ###################### */

.regEventDetails {
    margin-bottom: 0.8em !important;
}

.alert {
    margin-bottom: 1em;
}

.alert ul {
    margin: 15px auto;
}

.alert ul li {
    text-align: center;
    font-size: 1.5em;
    list-style-type: none;
}

.noPWInfo {
    background-color: #d32c46;
    padding: 5px 10px;
    color: #fff;
    font-size: 0.9em;
    line-height: 1.3em;
}

.noPWInfo a,
.noPWInfo a:hover {
    text-decoration: underline;
    color: #fff;
}

.PWInfo {
    background-color: #8b1b8b;
    margin: 1em auto;
    color: #fff;
    padding: 1em;
}

.PWInfo p {
    margin: 0;
}

.PWInfo a,
.PWInfo a:hover {
    color: #fff;
}

form.form-horizontal {
    margin: 0;
}

.PWInfo input:first-child {
    margin-bottom: 0.5em;
}

.vorwahl {
    color: rgba(0, 0, 0, 0.3);
}

.eventVerschicken,
.eventVerschickenNeu {
    display: none;
}

#one .event-cards__card .event-cards__thumb img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 0;
}

@media screen and (max-width: 980px) {
    #one img,
    #one iframe,
    #one table {
        max-width: 400px;
        width: 100%;
        margin: 0 auto 1em auto;
    }
    #one .event-cards__thumb img {
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;
    }

    /* Wichtig für CropIt !!! */
    .cropit-preview-image-container img {
        max-width: none !important;
        width: auto !important;
        margin: 0 !important;
    }

    .payicons {
        margin: 3em auto 0 auto !important;
    }

    .headingbg {
        color: #fff !important;
        background-color: #8b1b8b;
    }
}

@media screen and (min-width: 981px) {
    .payicons {
        margin: 13em auto 0 auto !important;
    }
}

.payicons, .payicons-page-2 {
    max-width: 250px !important;
}

.mapsidebar {
    margin: 1em auto;
}

.required {
    background: rgba(255, 0, 0, 0.15) !important;
    border: 1px solid rgba(255, 0, 0, .25) !important;
}

/* Benutzerprofil */

.profilphotoContainer {
    text-align: center;
}

.profilphotoContainer * {
    float: none;
}

.profilphoto {
    width: 100%;
    max-width: 250px;
    border-radius: 50%;
    border: 5px solid #fff;
    padding: 0 !important;
    margin-top: 20px;
}

.cropit-button {
    color: #ffffff;
    display: inline-block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 2.5em;
    padding: 0 0.8em !important;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #8b1b8b;
    cursor: pointer;
}

.turnbuttons {
    padding: 0 !important;
}

.cropit-preview {
    background-color: #f8f8f8;
    background-size: cover;
    margin: 20px auto;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    z-index: 999;
}

.cropit-preview-image-container {
    cursor: move;
    border-radius: 50%;
    z-index: 999;
    border: 5px solid #fff;
}

.cropit-preview-image {
    max-width: none;
}

.cropit-image-zoom-input {
    display: block;
    margin: 0 auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 5px;
    background: #eee;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    outline: none;
}

.image-size-label {
    margin-top: 10px;
}

button {
    margin-top: 10px;
}

.cropit-controls {
    display: none;
}

/* Inputs */

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 80%;
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.inputfile-1 + label {
    color: #f1e5e6;
    background-color: #d3394c;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #722040;
}

/* Accordion */

.accordion,
.accordion li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.accordion li {
    margin-bottom: 5px;
}

.accordion-heading {
    background-color: #fff;
    color: #9dc447;
    font-weight: bold;
    cursor: pointer;
    padding: 18px;
}

.accordion-content {
    background-color: #fff;
    color: #343434;
    padding: 18px;
}

.accordion-arrow {
    width: 20px;
    height: 20px;
    margin: 2px 5px;
    /*    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…MgMyA1LjcgMC40ICIgc3R5bGU9ImZpbGw6bm9uZTtzdHJva2U6IzlEQzQ0NyIvPjwvc3ZnPg==) no-repeat center center;*/
    background: url("../images/pfeilchen.png") no-repeat center center;
    float: right;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}

.spin {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

/* Locations */

.tableCenter tr td {
    text-align: center !important;
}

.noPadding {
    padding-top: 0 !important;
}

.datingLocationsWrapper {
    background-color: rgba(144, 144, 144, 0.075);
    padding: 1em;
}

.datingLocations {
    border: none !important;
}

.datingLocations tr {
    border: 0;
    background-color: transparent !important;
}

.datingLocations tr td {
    border-right: 1px solid #8b1b8b;
    padding: 0em 0.75em !important;
}

.datingLocations tr td:last-child {
    border-right: 0;
}

.datingLocations a {
    color: #8b1b8b;
    font-weight: bolder;
}

.datingLocations a:hover {
    color: #95D253;
}

.locationImg {
    margin-bottom: 10px;
}

.purple {
    color: #8b1b8b;
    font-weight: bolder;
}

.redtext {
    color: red !important;
    font-weight: bolder !important;
}

.block {
    display: block;
}

/* Seitenleiste */

.row.uniform h2 {
    padding: 1em 0 0 0;
    float: none;
    /* 07.02.2017 */
}

.row.uniform p {
    margin: 0.5em 0 0 0;
    padding: 0;
}

.row.uniform ul {
    padding-left: 1em;
}

/* Abstand zwischen Sections */

.marginBottom div {
    margin-bottom: 50px;
}

@media screen and (max-width: 1280px) {
    .marginBottom div {
        margin-bottom: 0.5em;
    }
}

/* Erfolgsstories */

.erfolgstoriesrow {
    margin-bottom: 2em !important;
}

.erfolgSmallBox {
    background-color: #8b1b8b;
    padding: 10px;
    color: #fff;
}

.team {
    text-align: center;
    font-size: 1.4em;
    line-height: 1.4em;
}

.team h2 {
    color: #fff !important;
}

/* Mobile Menu */


/*
.depth-1 {
    display: none !important;
}
*/

.hideSub {
    display: none !important;
}

.depth-1 {
    background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 1280px) {
    .loginButton > a {
        width: 65.94px;
        font-size: 9px;
    }
}

@media screen and (max-width: 980px) {
    .mobileOnly {
        display: block;
    }

    .desktopOnly {
        display: none;
    }

    .mt3 {
        margin-top: 3em !important;
    }
}

@media screen and (min-width: 981px) {
    .mobileOnly {
        display: none;
    }

    .desktopOnly {
        display: block;
    }
}

/* Alle Events */

a.uk-button.uk-button-small strong {
    color: #444 !important;
    text-transform: uppercase;
}

.allEventsList table {
    margin: 0;
    border: solid 1px rgba(144, 144, 144, 0.25);
    border-left: 0;
    border-right: 0;
}

.allEventsFilterTable tbody tr {
    background-color: rgba(144, 144, 144, 0.075);
    border: 0;
}

.allEventsFilterTable td {
    padding: .5em 0.25em !important;
    text-align: left !important;
}

.event-filter-age div {
    margin: 1px;
}

.events-gutschein {
    display: inline-block;
    margin-top: 10px !important;
}

.csd-input-button {
    -webkit-appearance: none;
    margin: 0;
    border: none;
    overflow: visible;
    font: inherit;
    color: #444;
    text-transform: none;
    display: inline-block;
    box-sizing: border-box;
    background: #f7f7f7;
    vertical-align: middle;
    line-height: 28px;
    min-height: 30px;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .3);
    background-origin: border-box;
    background-image: -webkit-linear-gradient(top, #fff, #eee);
    background-image: linear-gradient(to bottom, #fff, #eee);
    border-radius: 4px;
    text-shadow: 0 1px 0 #fff;
}

.csd-input-button label {
    float: left;
    width: 100%;
    color: #fff !important;
    margin: 0 !important;
}

.csd-input-button label span {
    text-align: center;
    display: block;
    width: 100%;
    padding: 0 12px;
}

.csd-input-button label input {
    position: absolute;
    top: -20px;
}

.csd-input-button-green {
    background-color: #82bb42;
    color: #fff;
    background-image: -webkit-linear-gradient(top, #9fd256, #6fac34);
    background-image: linear-gradient(to bottom, #9fd256, #6fac34);
    border-color: rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .4);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .2);
}

.csd-input-button-green:hover {
    background-color: #a3ec51;
    color: #fff;
    background-image: none
}

.csd-input-button-green input:checked + span {
    color: #fff;
    background-color: #d32c46;
    background-image: -webkit-linear-gradient(top, #ee465a, #c11a39);
    background-image: linear-gradient(to bottom, #ee465a, #c11a39);
}

.csd-input-button-purple {
    background-color: #9637ad;
    color: #fff;
    background-image: -webkit-linear-gradient(top, #bc64d2, #9637ad);
    background-image: linear-gradient(to bottom, #bc64d2, #9637ad);
    border: 1px solid rgba(0, 0, 0, .2);
    border-bottom-color: rgba(0, 0, 0, .3);
    border-radius: 4px;
    text-shadow: none;
}

.csd-input-button-purple:hover {
    background-image: -webkit-linear-gradient(top, #9637ad, #bc64d2);
    background-image: linear-gradient(to bottom, #9637ad, #bc64d2);
    color: #fff;
    text-shadow: none;
}

.csd-input-button-purple input:checked + span {
    color: #fff;
    background-color: #d32c46;
    background-image: -webkit-linear-gradient(top, #ee465a, #c11a39);
    background-image: linear-gradient(to bottom, #ee465a, #c11a39);
}

.csd-cities label span {
    width: 120px;
}

.altersgruppe {
    margin-bottom: 2em;
}

.altersgruppe .csd-input-button {
    margin-bottom: 3px;
}

.events-fragen * {
    float: none;
}

.events-fragen {
    margin-left: 1em;
}

.events-fragen a {
    color: #fff;
    display: block;
}

.monthsHeading {
    padding-left: 20px !important;
    background-color: #95D253;
    color: #fff;
    text-transform: uppercase;
    border: 5px solid white;
}

.noEvents {
    padding: 1em 2em;
}

.simpletable,
.simpletable tr,
.simpletable tr td {
    background-color: transparent !important;
    border: 0;
}

.simpletable td {
    padding: 2px;
}

.mt20 {
    margin-top: 20px;
}

@media screen and (min-width: 981px) {
    .mt60 {
        margin-top: 60px;
    }
}

.mt70 {
    margin-top: 70px;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mbhalf {
    margin-bottom: 0.5em;
}

.mb1 {
    margin-bottom: 1em !important;
}

.p0 {
    padding: 0 !important;
}

.h2side {
    padding-left: 2rem;
}

.block {
    display: block;
}

.nofloat * {
    float: none;
}

.buttonsSidebar ul {
    float: none;
}

.buttonsSidebar li {
    display: block;
    margin-top: 10px;
}

.whitesidebox ul {
    background-color: #fff;
    padding: 1em 1em;
}

.whitesidebox ul li {
    list-style-type: none;
    color: #868686;
}

/* Login etc */

.button-big {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    background-color: transparent;
    border: 0;
    border: solid 2px #7f7f7f;
    border-radius: 0 !important;
    color: #7f7f7f !important;
    cursor: pointer;
    display: inline-block;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    height: 3em;
    height: calc(3em + 2px);
    letter-spacing: 0.05em;
    line-height: 3em;
    padding: 0 1.25em;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

.button-big-green {
    background-color: #95D253;
    border: 0;
    color: #ffffff !important;
    height: 3em;
}

.button-big-green:hover {
    background-color: #8b1b8b !important;
}

/* Footer */

ul.major-icons {
    margin-bottom: 3em !important;
}

ul.major-icons a {
    color: #444 !important;
}

.garantie {
    max-width: 300px;
    display: block;
    margin: 0 auto 1em auto;
}

/* Gutscheinseite */

.purpletext {
    color: #8b1b8b !important;
    font-weight: 900 !important;
}

/* Allgemein */

.major-icons a:hover {
    text-decoration: none !important;
}

.unterschrift {
    max-width: 250px;
}

/* Chat */

.arrowchat_giphy_search {
    height: auto !important;
    border: none !important;
    background-color: transparent !important;
    line-height: normal !important;
    font-size: 13px !important;
    padding: 0 !important;
}

.arrowchat_giphy_search:focus {
    border: none !important;
    box-shadow: none !important;
}

.kontakttabelle table tr td:first-child {
    text-align: center !important;
}

.fullPayInfo {
    text-align: left;
    padding: 10px;
    background-image: none;
    background-color: transparent;
    border: 1px solid rgb(191, 0, 0);
    border-radius: 4px;
}
@media screen and (max-width: 1280px) {
    .event-cards__card {
        text-align: left;
        padding: 20px 26px;
    }
}

@media screen and (max-width: 880px) {
    .event-cards .event-cards__card .event-cards__thumb_container .places-left{
        top: 81%;
        right: -40px;
    }
    .event-cards .event-cards__card .event-card__content-padding{
        padding: 10px 10px 15px 10px;
    }
    .event-cards__card{
        padding: 20px 20px;
    }
    .event-cards .event-cards__card .event-cards__thumb_container .event-cards__aktion p{
        margin-left: 5px;
        font-size: 14px;
    }
}
@media screen and (max-width: 355px) {
    .event-cards .event-cards__card .event-cards__thumb_container .favorite-icon{
        width: 20px !important;
    }
    .event-cards .event-cards__card .event-cards__thumb_container .event-cards__aktion p{
        margin-left: 5px;
        font-size: 14px;
    }
    .event-cards .event-cards__card .event-cards__title{
        font-size: 18px;
    }
}